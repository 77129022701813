import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import BoxTypo from "../components/BoxTypo";
import { ContactSupportOutlined } from "@material-ui/icons";

function IntroductionCard() {
  const [igDatas, setDatas] = useState([""]);
  const data = useStaticQuery(graphql`
    query {
      introduction_logo: file(relativePath: { eq: "introduction_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      igHeader: file(relativePath: { eq: "igHeader.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      igCenter: file(relativePath: { eq: "igCenter.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const nlRegex = /(\r\n|\r|\n)/g;
  const nl2br = (str = "") =>
    str.split(nlRegex).map((line, i) =>
      line.match(nlRegex) ? (
        <>
          <br key={i} />
        </>
      ) : (
        line
      )
    );
  useEffect(() => {
    fetch(
      "https://graph.instagram.com/me/media?fields=id,media_url,caption&access_token=IGQVJYODRmZAFA0N1dVQnUxTUduNnFILWY4Q2U2QjltQmh5QzZAzbjc3MG1sQmxYVzJJbU1wWDk5ZA1FWWUFKZAXFXTy1RaWphdHRsSWFJSGxsSnNyMWxwYkhwRFVPYWxadlFiNlVoYW8wZADFXMVhVZAGlKZAgZDZDf"
      //IGQVJYQXduWEVVVDV5QndUclNFYUVLM2o3ZA3JxY2ZA4TG5TU3hpQTA1bjl5Q1NtckFLMXV4dThxZATQtOWNyN3UyY1JRRXE5aWNpTXozRE82djJZAQXZABUUVrTXNVOXhGaDdvMzdYREN3aDcxQlA1MXM5SgZDZD
      //2022/06/01 更新　IGQVJVczV3UjRXdTgxSExPLUM2RGtjWGtVbGcxUUhzblhqSVB6S250OGMwWmVmUDV1Qi15T19SeWg1OFBFbmJqZAV8xbENKTDNHbl9vSHdVUUtkN2l1ekhVN0hoVzN2bE0xVkZA0Y3FYQTVjY3d3bzNicQZDZD
      //2022/06/01 old     IGQVJWVzNmODhTODhtNE82dndrSUIzUXY5aVlWM2RXTnVJMTBZAdmF1RERweTFHdFZAWcTAwenEyUzRST2dGTHJVRGlBN21BazMyaDNEbkxVaXdfUERWVV9VM0pfNXhNUWh6ZAHc1TDg1RE03ZATN4RnlZAZAQZDZD
    )
      .then((response) => response.json())
      .then((c) => {
        console.log("成功");
        setDatas(c.data);
      })
      .catch((e) => {
        console.log("エラーです", { e });
      });
  }, setDatas);

  const splitHashtag = (caption = "") => {
    const splitArray = caption.split("#");
    const bunkatu = splitArray.slice(1, 15);
    return [splitArray[0], bunkatu];
  };

  return (
    <>
      <Hidden mdUp>
        <Box width="100%" maxWidth={"540px"} m={"auto"}>
          <Grid container>
            {igDatas?.slice(0, 6).map((c) => {
              return (
                <Grid item xs={12} md={12}>
                  <Link
                    to="https://www.instagram.com/syo_fishcation/?hl=ja"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <Box
                      flex={2}
                      width="100%"
                      maxWidth="500px"
                      m={"auto"}
                      borderRadius={5}
                      my={3}
                      boxShadow={5}
                      overflow="hidden"
                      bgcolor={"#fff"}
                    >
                      <Box height="320px" borderRadius={5} overflow="hidden">
                        <Box width="100%" height="50px" bgcolor="#fff">
                          <Img fluid={data.igHeader.childImageSharp.fluid} />
                        </Box>
                        <img
                          src={c?.media_url}
                          style={{
                            width: "100%",
                            height: "400px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Img
                        fluid={data.igCenter.childImageSharp.fluid}
                        style={{
                          margin: "auto",
                          width: "95%",
                          marginTop: "8px",
                        }}
                      />
                      <BoxTypo
                        fs={["12px"]}
                        ls={["2px"]}
                        lh={["200%"]}
                        width="90%"
                        m={"auto"}
                        height="250px"
                        my={1}
                        pb={1}
                        overflow="hidden"
                      >
                        <Hidden mdUp>
                          {splitHashtag(c.caption)[0].substr(0, 65)}
                          {+splitHashtag(c.caption)[0].length > 65 ? "..." : ""}
                        </Hidden>
                        <Hidden xsDown>
                          {splitHashtag(c.caption)[0].substr(0, 140)}
                          {splitHashtag(c.caption)[0].length > 140 ? "..." : ""}
                        </Hidden>
                        <br />
                        <br />
                        <BoxTypo
                          fs={["12px"]}
                          ls={["2px"]}
                          lh={["200%"]}
                          width="100%"
                          m={"auto"}
                          color="#80B7F5"
                        >
                          {splitHashtag(c.caption)[1].map((t) => {
                            return "#" + t;
                          })}
                          もっと見る...
                        </BoxTypo>
                      </BoxTypo>
                    </Box>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box
          width="100%"
          maxWidth={"540px"}
          m={"auto"}
          height={"800px"}
          overflow={"scroll"}
          bgcolor={"#f5f5f5"}
        >
          <Grid container>
            {igDatas?.slice(0, 6).map((c) => {
              return (
                <Grid item xs={12} md={12}>
                  <Link
                    to="https://www.instagram.com/syo_fishcation/?hl=ja"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <Box
                      flex={2}
                      width="100%"
                      maxWidth="500px"
                      m={"auto"}
                      borderRadius={5}
                      my={3}
                      boxShadow={5}
                      overflow="hidden"
                      bgcolor={"#fff"}
                    >
                      <Box height="320px" borderRadius={5} overflow="hidden">
                        <Box width="100%" height="50px" bgcolor="#fff">
                          <Img fluid={data.igHeader.childImageSharp.fluid} />
                        </Box>
                        <img
                          src={c?.media_url}
                          style={{
                            width: "100%",
                            height: "400px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Img
                        fluid={data.igCenter.childImageSharp.fluid}
                        style={{
                          margin: "auto",
                          width: "95%",
                          marginTop: "8px",
                        }}
                      />
                      <BoxTypo
                        fs={["12px"]}
                        ls={["2px"]}
                        lh={["200%"]}
                        width="90%"
                        m={"auto"}
                        height="250px"
                        my={1}
                        pb={1}
                        overflow="hidden"
                      >
                        <Hidden mdUp>
                          {splitHashtag(c.caption)[0].substr(0, 65)}
                          {+splitHashtag(c.caption)[0].length > 65 ? "..." : ""}
                        </Hidden>
                        <Hidden xsDown>
                          {splitHashtag(c.caption)[0].substr(0, 140)}
                          {splitHashtag(c.caption)[0].length > 140 ? "..." : ""}
                        </Hidden>
                        <br />
                        <br />
                        <BoxTypo
                          fs={["12px"]}
                          ls={["2px"]}
                          lh={["200%"]}
                          width="100%"
                          m={"auto"}
                          color="#80B7F5"
                        >
                          {splitHashtag(c.caption)[1].map((t) => {
                            return "#" + t;
                          })}
                          もっと見る...
                        </BoxTypo>
                      </BoxTypo>
                    </Box>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Hidden>
    </>
  );
}

export default IntroductionCard;
