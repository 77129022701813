import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import { Box, Grid, Hidden, Switch } from "@material-ui/core";
import BoxTypo from "../components/BoxTypo";

const containerStyle = {
  height: "100vh",
  width: "100%",
};

const center = {
  lat: 38.81568162166641,
  lng: 139.78792950505735,
};

const divStyle = {
  background: "white",
  fontSize: 7.5,
  paddingRight: "8px",
};

const MyComponent = (props) => {
  const {
    WorkLocationArray,
    FishingLocationArray,
    StayLocationArray,
    TransportLocationArray,
  } = props;

  // const [size, setSize] =
  //   (useState < undefined) | (google.maps.Size > undefined);
  const [size, setSize] = useState(300);
  const infoWindowOptions = {
    pixelOffset: size,
  };
  const createOffsetSize = () => {
    return setSize(new window.google.maps.Size(0, -45));
  };
  const [isWorkPrint, setWorkPrint] = useState(true);
  const [isTomariPrint, setTomariPrint] = useState(false);
  const [isTsuriPrint, setTsuriPrint] = useState(false);
  const [isTransPortPrint, setTransPortPrint] = useState(false);

  return (
    <>
      <Box position="relative" mb={12} mt={4}>
        <BoxTypo
          className="tac"
          fs="24px"
          ls="10px"
          Tsukubrdgothic
          fw="700"
          mb="64px"
        >
          MAP
        </BoxTypo>
        <Box
          position="absolute"
          bottom={0}
          width={["40%", "40%", "60%"]}
          bgcolor={"rgba(0,0,0,0.5)"}
          zIndex={999}
        >
          <Grid container className="w90 ma" justify="space-between">
            <Grid item xs={12} md={3}>
              <BoxTypo
                color="white"
                fs={["12px", "12px", "18px"]}
                ls={["2px"]}
                lh={["200%"]}
                Tsukubrdgothic
              >
                コワーキング
              </BoxTypo>
              <Switch
                checked={isWorkPrint}
                onChange={() => setWorkPrint((c) => !c)}
                name="workprint"
              ></Switch>
            </Grid>
            <Grid item xs={12} md={3}>
              <BoxTypo
                color="white"
                fs={["12px", "12px", "18px"]}
                ls={["2px"]}
                lh={["200%"]}
                Tsukubrdgothic
              >
                釣りスポット
              </BoxTypo>
              <Switch
                checked={isTsuriPrint}
                onChange={() => setTsuriPrint((c) => !c)}
                name="workprint"
                inputProps={{ "aria-label": "secondary checkbox" }}
              ></Switch>
            </Grid>
            <Grid item xs={12} md={3}>
              <BoxTypo
                color="white"
                fs={["12px", "12px", "18px"]}
                ls={["2px"]}
                lh={["200%"]}
                Tsukubrdgothic
              >
                宿泊施設
              </BoxTypo>
              <Switch
                checked={isTomariPrint}
                onChange={() => setTomariPrint((c) => !c)}
                name="Tomariprint"
                inputProps={{ "aria-label": "primary checkbox" }}
              ></Switch>
            </Grid>
            <Grid item xs={12} md={3}>
              <BoxTypo
                color="white"
                fs={["12px", "12px", "18px"]}
                ls={["2px"]}
                lh={["200%"]}
                Tsukubrdgothic
              >
                移動手段
              </BoxTypo>
              <Switch
                checked={isTransPortPrint}
                onChange={() => setTransPortPrint((c) => !c)}
                name="TransPortprint"
                inputProps={{ "aria-label": "primary checkbox" }}
              ></Switch>
            </Grid>
          </Grid>
        </Box>
        <LoadScript
          googleMapsApiKey="AIzaSyALz2cKwEUyLWwZDovqLokovaUfSI3vNIU"
          onLoad={() => createOffsetSize()}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            {isWorkPrint ? (
              WorkLocationArray.map((pic) => {
                return (
                  <>
                    <Marker position={pic} />
                    <InfoWindow position={pic} options={infoWindowOptions}>
                      <div style={divStyle}>
                        <BoxTypo
                          fs={["12px"]}
                          ls={["2px"]}
                          lh={["200%"]}
                          Tsukubrdgothic
                        >
                          {pic.place}
                        </BoxTypo>
                        {/* <a
                          href={pic.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          詳しくはこちら
                        </a> */}
                      </div>
                    </InfoWindow>
                  </>
                );
              })
            ) : (
              <></>
            )}
            {isTsuriPrint ? (
              FishingLocationArray.map((pic) => {
                return (
                  <>
                    <Marker position={pic} />
                    <InfoWindow position={pic} options={infoWindowOptions}>
                      <div style={divStyle}>
                        <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} Ts>
                          {pic.place}
                        </BoxTypo>
                        {/* <a
                          href={pic.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          詳しくはこちら
                        </a> */}
                      </div>
                    </InfoWindow>
                  </>
                );
              })
            ) : (
              <></>
            )}
            {isTomariPrint ? (
              StayLocationArray.map((pic) => {
                return (
                  <>
                    <Marker position={pic} />
                    <InfoWindow position={pic} options={infoWindowOptions}>
                      <div style={divStyle}>
                        <BoxTypo
                          fs={["12px"]}
                          ls={["2px"]}
                          lh={["200%"]}
                          Tsukubrdgothic
                        >
                          {pic.place}
                        </BoxTypo>
                        {/* <a
                          href={pic.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          詳しくはこちら
                        </a> */}
                      </div>
                    </InfoWindow>
                  </>
                );
              })
            ) : (
              <></>
            )}
            {isTransPortPrint ? (
              TransportLocationArray.map((pic) => {
                return (
                  <>
                    <Marker position={pic} />
                    <InfoWindow position={pic} options={infoWindowOptions}>
                      <div style={divStyle}>
                        <BoxTypo
                          fs={["12px"]}
                          ls={["2px"]}
                          lh={["200%"]}
                          Tsukubrdgothic
                        >
                          {pic.place}
                        </BoxTypo>
                        {/* <a
                          href={pic.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          詳しくはこちら
                        </a> */}
                      </div>
                    </InfoWindow>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </GoogleMap>
        </LoadScript>
      </Box>
    </>
  );
};

export default MyComponent;
